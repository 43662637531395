import * as PropTypes from "prop-types";

const Hero = function ({ slice }) {
  const { heading, image, variant, show_logo } = slice.primary;

  const styles = {
    backgroundImage: `url(${image.url})`,
  };

  return (
    <div
      className={`georgetown-hero cover-35 flex items-center justify-center ${variant}`}
      data-analytics-component="hero"
      style={styles}
    >
      {show_logo ? (
        <div>
          <img aria-hidden alt="Georgetown University Pre-College Online Program" width="360" height="155" src="/logo-hero.png" />
          <h1 className="sr-only">
            Georgetown University Pre-College Online Program
          </h1>
        </div>
      ) : <h1>{heading}</h1>}
    </div>
  );
};

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Hero;
